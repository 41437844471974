<template>
  <div class="content">
    <div class="create">
      <div class="row">
        <div class="col-4">
          <input type="text" class="form-control" placeholder="Name" v-model="name" />
        </div>
        <div class="col-4">
          <input type="text" class="form-control" placeholder="slug" v-model="slug" disabled />
        </div>
        <div class="col-1">
          <input type="checkbox" class="form-check-input" id="checkbox" v-model="published" />
          <label for="checkbox">published</label>
        </div>
        <div class="col-2">
          <button class="vave-btn btn-blue" @click="createCollection()" :disabled="!this.name">Create</button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table">
        <thead class="head">
          <tr>
            <th class="ps-4">Id</th>
            <th>Name</th>
            <th>Slug</th>
            <th>Published</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="collection in collectionsList" :key="collection.id">
            <td class="ps-4">{{collection.id}}</td>
            <td>{{collection.name}}</td>
            <td>{{collection.slug}}</td>
            <td>{{collection.published ? 'yes' : 'no'}}</td>
            <td>
              <div class="d-flex">
                <router-link class="vave-btn me-1" :to="'/collections/' + collection.id">
                  <i class="fa-regular fa-pen-to-square me-1"></i>
                  Edit
                </router-link>
                <div class="vave-btn btn-outline-red" @click="deleteAction(collection.id)">
                  <i class="fa-regular fa-trash"></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";

export default {
  data() {
    return {
      name: "",
      published: true,
      collectionsList: [],
    };
  },
  computed: {
    slug() {
      return this.name.toLowerCase().replace(/ /g, "-");
    },
  },
  mounted() {
    this.retrieveCollections();
  },
  methods: {
    retrieveCollections() {
      if (this.collectionsList.length <= 0) {
        this.getCollections();
      }
    },
    deleteAction(id) {
      if (!confirm("do you want to delete the collection " + id + "?")) {
        return;
      }
      axios.delete("/api/admin/collections/" + id).then(() => {
        this.getCollections();
      });
    },
    getCollections() {
      axios.get("/api/admin/collections").then((response) => {
        if (response.data.data) {
          this.collectionsList = response.data.data;
        }
      });
    },
    createCollection() {
      if (
        !confirm(
          'do you want to create a new collection with name "' +
            this.name +
            '" and slug "' +
            this.slug +
            '"?'
        )
      ) {
        return;
      }
      axios
        .post("/api/admin/collections", {
          name: this.name,
          slug: this.slug,
          published: this.published,
        })
        .then(
          () => {
            this.name = "";
            this.published = true;
            this.getCollections();
          },
          (e) => {
            console.log(e.response.data);
            alert(e.response.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.create {
  padding-bottom: 20px;
}
</style>